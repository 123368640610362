
//- ####################################################
//- section_actualites
//- ####################################################

.section_actualites{
	
	margin: 50px 0 20px 0;
	
	h2{
		margin-left: 15px;
		margin-bottom: 10px;
	}

	.liste{
		display: flex;
		flex-wrap: wrap;


		.bloc{
			width: 33%;
			padding: 20px;

			@include media("<750px"){
				flex: 50%;
			}
			@include media("<550px"){
				flex: 100%;
			}
			
			.titre{
				text-transform: uppercase;
				font-weight: 700;
				margin-bottom: 6px;
			}

			p.detail{
				font-style: italic;
			}

			&.actu{
				@include gradient ($color1: lighten($brown, 45%), $color2: #fff, $angle:145deg);

				.titre{
					color:$brown;
				}

				p.detail{
					color: $grey;
				}
			}

			&.avis{
				@include gradient ($color1: $smoke, $color2: #fff, $angle:145deg);

				.titre{
					color:$grey_light;
				}
			}


		}
	}


}