
//- ####################################################
//- Page Gamme de produits
//- ####################################################

.page_gamme_produits{
	
	
	//- Intro
	//- =============================
	.intro{

		background: $smoke;
		padding: $main_padding;

		h1{
			margin-top: 10px;

			@include media("<750px"){
				text-align: center;
				margin-bottom: 16px;
			}
		}


		.photo{
			display: inline-block;
			margin: 20px auto;
			width: 260px;
			height: 260px;
			border-radius: 50%;
			overflow: hidden;


			img{
				@include image-cover(100%, 100%);
			}

			@include media("<1000px"){
				width: 200px;
				height: 200px
			}
			@include media("<750px"){
				display: block;
				margin: 0 auto;
			}

		}

		.texte{
			display: inline-block;
			width: calc(100% - 260px - 5%);
			margin-left: 5%;
			margin-top: 30px;
			vertical-align: top;
			line-height: 22px;

			@include media("<750px"){
				text-align: center;
				display: block;
				width: auto;
				margin-left: 0;
				margin-top: 0;
				padding: 20px;
			}
		}

	}

	//- blocs_icones
	//- =============================
	.blocs_icones{
		margin-top: 40px;
	}


	//- Liste produits
	//- =============================
	.liste_produit{
		margin-top: 32px;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;


		a.produit{

			flex: 1 0 auto;
			max-width: 170px;

			text-align: center;
			background: #eee;
			text-decoration: none;
			color: $brown;

			&:hover{
				color: $green;
				background: #fff;
				box-shadow: 0 0 16px #999;
			}

			&:not(:last-child){
				// margin-right: 12px;
			}

			img{

			}

			.texte{
				padding: 6px 12px;
			}
		}
	}




	//- Recettes
	//- =============================
	.recettes{
		margin-top: 40px;
	}



	//- ####################################################
	//- Bandeau
	//- ####################################################
	.bandeau{
		margin-top: 30px;
		margin-bottom: 30px;
		img{
			width: 70px;
			height: auto;
			position: absolute;
			top: 35px;
			left: 60px;
			opacity: 0.4;
		}
	}




}