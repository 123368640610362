
//- ####################################################
//- Page d'accueil
//- ####################################################
.page_accueil{


	//- ####################################################
	//- Section introduction
	//- ####################################################

	.section_intro{
		background: $smoke;
		padding: $main_padding;
		display: flex;
		flex-wrap: wrap;


		//- Colonne 1
		//- =============================

		.col_1{
			flex: 1;

			.intro{
				margin-bottom: 20px;
			}

			
		}

		//- Colonne 2
		//- =============================

		.col_2{
			width: 450px;

			@include media("<1290px"){
				width: 300px;
			}
			@include media("<1050px"){
				width: 100%;
			}

			.photo{
				margin: 20px auto;
				width: 260px;
				height: 260px;
				border-radius: 50%;
				overflow: hidden;

				img{
					@include image-cover(100%, 100%);
				}

				@include media("<1050px"){
					display: none;
				}
			}			
		}
	}


	//- ####################################################
	//- Section Icones
	//- ####################################################

	.section_icones{
		margin-top: 40px;

		//- Bloc icones
		//- =============================

		.blocs_icones{
			// Dans main.scss
		}
	}


	//- ####################################################
	//- Section Facebook et avis
	//- ####################################################
	.section_fb_avis{
		margin-top: 60px;
		
		.grid{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 30px;

			.tous_les_avis{
				margin-right: 40px;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				max-width: 50%;

				@include media("<1000px"){
					max-width: 100%;
				}
			}

			.avis{
				text-align: center;
				margin-bottom: 40px;

				img.avatar{
					width: 60px;
					margin-bottom: 10px;
				}
				&.tripadvisor img.avatar{
					width: 110px;
				}

				.texte{
					font-size: 18px;
					font-style: italic;
				}

				.nom{
					color:$brown;
					font-weight: bold;
					margin-top: 8px;
				}
			}

			.fb_comp{
				// width: 500px;
			}
		}
		

		//- Hack du plugin facebook
		.social-widget{
			// overflow: hidden;

			.fb-page{
				margin-top: -40px;
			}
		}
	}


	//- ####################################################
	//- Bandeau
	//- ####################################################
	.bandeau{
		img{
			width: 70px;
			height: auto;
			position: absolute;
			top: 35px;
			left: 60px;
			opacity: 0.4;
		}
	}




	//- ####################################################
	//- Section Visites et découverte
	//- ####################################################

	.section_visites_decouverte{
		margin-top: 40px;
		display: flex;
		flex-wrap: wrap;

		h2{
			flex: 0 0 100%;
		}

		.textes{
			flex: 2;
		}

		.quote{
			margin-top: 30px;
			margin-bottom: 30px;
		}


		.photo{
			flex: 1.2;
			margin-left: 30px;

			@include media("<750px"){
				flex: 100%;
				margin: 0;
			}
		}

	}

}