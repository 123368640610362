//- ####################################################
//- Imports
//- ####################################################
@import "vendors/201bundle";
@import "theme";
@import "vendors/normalize";

//- ####################################################
//- Main
//- ####################################################
body{
	font-family: Open Sans;
	// @include gradient ($color1: $pc, $color2: $green_alt, $angle:135deg);
	background-image: linear-gradient(to right top, #d0dc97, #d0dc97, #d0dc97, #d0dc97, #d0dc97);
	color: $text_color;
	font-size: 1em;

	@include media("<750px"){
		font-size: .8em;
	}
}

h1, h2, h3, h4{
	font-family: Grocery rounded;
	color: darken($brown_light, 10%);
	margin: 0;
	font-weight: normal;
}
h1, h2{
	font-size: 2.5em;
}

p{
	line-height: 1.4rem;
	margin: 8px 0;
	@include media("<750px"){
		line-height: 1.1rem;
	}
}

a{
	// text-decoration: none;
	color: $pc;
	@include hardware;

	&:hover{
		color: $red;
	}
}

img{
	max-width: 100%;
	height: auto;
}

.bold{
	font-weight: 700;
}

.quote{
	font-style: italic;
	font-size: 1.3rem;
	line-height: 1.7rem;
	color: $pc;
	margin: 20px 10px 0 10px;
	text-align: center;

	@include media("<750px"){
		font-size: 1.1rem;
		line-height: 1.5rem;
	}
}

.bandeau{
	position: relative;
	margin-top: 60px;
	padding: 40px;
	@include gradient ($color1: $brown, $angle:135deg);
	@include box-shadow;
	color: #fff;
	text-align: center;
	font-size: 20px;
	line-height: 32px;
	font-style: italic;
}

//- Logo Bienvenue à la ferme
.bienvenue_a_la_ferme{
	position: fixed;
	top: 0;
	left: 0;
	width: 140px;
	height: auto;
	z-index: 10;

	@include media("<1500px"){
		position: absolute;
	}

	@include media("<600px"){
		width: 110px;
	}
}



//- ####################################################
//- Layout
//- ####################################################

:root{
	--main_margin: 60px;
	--main_padding: 20px;

	@include media("<1200px"){
		--main_margin: 30px;
		--main_padding: 10px;

	}
	@include media("<600px"){
		--main_margin: 5px;
		--main_padding: 8px;

	}
}

$main_margin: var(--main_margin);
$main_padding: var(--main_padding);



//- Container de toute la page
.main_container{
	position: relative;
	margin: $main_margin auto;
	padding: $main_padding;
	background: #fff;
	min-height: calc(100vh - (2 * #{$main_margin}) - (2 * #{$main_padding}));
	@include box-shadow;
	max-width: 1300px;
	overflow: hidden;

	@include media("<1400px"){
		max-width: calc(100vw - (2 * #{$main_margin}));
	}
	
	
}

//- Container global
.main_content{
	padding: $main_padding;
}

//- Container des contenus
.content{
	@include content-show();
	margin-top: 10px;
}


//- ####################################################
//- Header
//- ####################################################

header{
	position: relative;
	height: 300px;
	background: url(../assets/images/background2.jpg) 50% 50% no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;

	@include media("<600px"){
		height: 250px;
	}   

	video{
		mix-blend-mode: lighten;
		opacity: 0.3;
		position: absolute;
		// top: -100px;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.logo{
		width: 300px;
		height: auto;

		@include media("<560px"){
			width: 250px;
		}
		@include media("<380px"){
			width: 200px;
		}
	}

	.baseline{
		color: #fff;
		margin-top: 20px;
		font-style: italic;
		font-size: 1.3rem;
		text-align: center;
		
		br{
			display: none;
		}

		@include media("<700px"){
			font-size: 1.1rem;
		}

		@include media("<560px"){
			font-size: 1rem;
			margin-top: 10px;

			br{
				display: initial;
			}
			span.tiret{
				display: none;
			}
		}
		@include media("<380px"){
			font-size: 0.9rem;
		}

	}

	.grass_left{
		position: absolute;
		bottom: -6px;
		right: calc(50% + 20px);
		width: 986px;
		height: 30px;
	}

	.grass_right{
		position: absolute;
		bottom: -8px;
		left: calc(50% - 10px);
		width: 1076px;
		height: 45px;
	}

	.snail1{
		position: absolute;
		bottom: -6px;
		right: 50%;
		width: 50px;
		height: auto;
		animation: snail1 300s linear forwards;
	}

	.snail2{
		position: absolute;
		top: -2px;
		right: 30px;
		width: 40px;
		height: auto;
		animation: snail2 480s linear forwards;
	}

	@keyframes snail1{
		0%{transform: translateX(0);}
		100%{transform: translateX(50vw);}
	}

	@keyframes snail2{
		0%{transform: translateX(0);}
		100%{transform: translateX(-90vw);}
	}
}


//- ####################################################
//- Navigation
//- ####################################################
nav{
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	padding-top: 10px;
	text-align: center;

	@include media("<930px"){
		font-size: .9rem;
	}
	@include media("<840px"){
		font-size: .8rem;
	}



	a{
		position: relative;
		text-decoration: none;
		display: inline-block;
		padding: 15px 12px;
		position: relative;
		color: $grey_light;

		&:hover, &.active{
			color: $red;
			text-decoration: underline;
			text-decoration-thickness: 1px;
			text-decoration-color: $brown;
			text-underline-offset: 4px;
		}


		//- Separator
		&:after{
			@include content;
			width: 1px;
			height: 50%;
			right: 0;
			top: 25%;
			background: $pc;
		}

		&:last-child:after{
			display: none;
		}
	}

	.menu-child-container {
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translate(-25%, 100%);
		display: block;
		z-index: 1;
		background: #fff;
		width: 230px;
		text-transform: none;
		padding: 10px;
		box-shadow: 0 0 10px #999;

		a{
			padding: 0;
			line-height: 2;
			&:after{
				display: none;
			}
		}
	}
}


//- ####################################################
//- Footer
//- ####################################################

footer{
	position: relative;
	$height: 120px;
	padding-right: 30px;
	padding-left: 30px;
	@include gradient($grey);
	height: $height;
	margin: 80px calc(#{$main_padding} * -1) calc(#{$main_padding} * -1) calc(#{$main_padding} * -1);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	font-size: 1.4rem;
	color: $sc;
	font-family: Grocery rounded;
	text-align: center;

	@include media("<930px"){
		font-size: 1.2rem;
	}
	@include media("<780px"){
		font-size: 1rem;

	}
	@include media("<690px"){
		font-size: 1.1rem;
		padding: 30px 10px;
	}

	.contact{
		color: $brown_light;
	}

	&:before{
		content:"";
		position: absolute;
		bottom: calc(#{$height} - 2px);
		left: 0;
		width: 2000px;
		height:40px;
		background: url(../assets/images/grass-dark.svg) no-repeat;
	}

	&:after{
		content:"";
		position: absolute;
		bottom: calc(#{$height} - 8px);
		right: 0;
		width: 50px;
		height:50px;
		background: url(../assets/images/snail_footer.svg) no-repeat;
		animation: snailfooter 480s linear forwards;
		opacity: 0.7;
	}

	@keyframes snailfooter{
		0%{transform: translateX(0);}
		100%{transform: translateX(-90vw);}
	}

}


//- ####################################################
//- Composants
//- ####################################################



//- Galerie
//- =============================
.galerie{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	--size_default: 25%;

	//- Si 3 ou 6 photos par ligne
	&.impair{
		.img_container{
			--size_default: 33%;
		}
	}

	.img_container{

		img{
			@include image-cover(100%, 100%);
		}

		background: #fff;
		display: inline-block;
		width: var(--size_default);
		min-width: 150px;
		height: 200px;
		padding: 10px;
		overflow: hidden;
		@include media("<900px"){
			width: 50%;
		}
		@include media("<440px"){
			width: 90%;
		}
	}
	
}


//- Bloc icones
//- =============================

.blocs_icones{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-right: 40px;
	margin-left: 40px;

	.bloc{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $brown;
		width: 20%;
		text-align: center;

		@include media("<750px"){
			flex: 50%;
			margin-bottom: 40px;
		}

		img{
			width: 90px;
			height: auto;
			margin-bottom: 8px;
		}
		span{

		}
	}
}






//- ####################################################
//- Pages
//- ####################################################

@import "page_accueil";
@import "actualites";
@import "page_contact";
@import "page_ferme_auberge";
@import "page_gamme_produits";

