//- ####################################################
//- Theme
//- ####################################################


//- Colors
//- =============================

$grey: #474448;
$grey_light: #888;
$smoke: #f6f4f3;

$brown: #83624C;
$brown_light: #b4a77a;

$red: #f55d3e;

$sand: #F5D0A3;

// $green: #648B1E;
// $green_ligth: #D2E9B8;

$green: #61892f;
$green_ligth: lighten($green, 15%);

$green_alt: #d0db97;


$pc: $green; //- Primary-color
$sc: $green_alt; //- Secondary color
$text_color: $grey_light;


//- Fonts
//- =============================

// open-sans-300
@include import-font (
	$font-family:"Open Sans",
	$name:"open-sans-v17-latin-300",
	$url:"../assets/fonts/open-sans",
	$style:"normal",
	$weight:"300");

// open-sans regular
@include import-font (
	$font-family:"Open Sans",
	$name:"open-sans-v17-latin-regular",
	$url:"../assets/fonts/open-sans",
	$style:"normal",
	$weight:"400");

// open-sans regular italic
@include import-font (
	$font-family:"Open Sans",
	$name:"open-sans-v17-latin-italic",
	$url:"../assets/fonts/open-sans",
	$style:"italic",
	$weight:"400");

// open-sans-600
@include import-font (
	$font-family:"Open Sans",
	$name:"open-sans-v17-latin-600",
	$url:"../assets/fonts/open-sans",
	$style:"normal",
	$weight:"600");

// open-sans-700
@include import-font (
	$font-family:"Open Sans",
	$name:"open-sans-v17-latin-700",
	$url:"../assets/fonts/open-sans",
	$style:"normal",
	$weight:"700");

// open-sans-800
@include import-font (
	$font-family:"Open Sans",
	$name:"open-sans-v17-latin-800",
	$url:"../assets/fonts/open-sans",
	$style:"normal",
	$weight:"800");

// Grocery rounded
@include import-font-ttf (
	$font-family:"Grocery rounded",
	$name:"GroceryRounded",
	$url:"../assets/fonts",
	$style:"normal",
	$weight:"300");
