
//- ####################################################
//- Page Contact
//- ####################################################

.page_contact{
	
	
	
	.blocs_icones{
		margin-top: 40px;
		margin-bottom: 40px;
		img{
			height: 50px;
			width: auto;
		}
	}


	//- Icones
	//- =============================
	#map {
		width: 100%;
		height: 500px;
		background-color: grey;
	}

}