//- ####################################################
//- Page ferme auberge
//- ####################################################
.page_ferme_auberge{
	

	//- Intro
	//- =============================
	.intro{

		h2{
			margin-top: 10px;
		}

		background: $smoke;
		padding: $main_padding;

		.photo{
			display: inline-block;
			margin: 20px auto;
			width: 260px;
			height: 260px;
			border-radius: 50%;
			overflow: hidden;


			img{
				@include image-cover(100%, 100%);
			}

			@include media("<1000px"){
				width: 200px;
				height: 200px
			}
			@include media("<750px"){
				display: block;
			}

		}

		.texte{
			display: inline-block;
			margin-top: 30px;
			margin-left: 50px;
			width: calc(100% - 260px - 50px);
			vertical-align: top;

			@include media("<1000px"){
				margin-left: 20px;
				width: calc(100% - 200px - 20px);
			}
			@include media("<750px"){
				margin: auto;
				width: 100%;
				text-align: center;
			}
		}

		.quote{
			margin-top: 30px;
			color: $brown;
			line-height: 32px;

			span.contact{
				color: #333;
				font-size: 19px;
			}

			@include media("<750px"){
				margin-top: 18px;
			}
		}
	}

	//- Icones
	//- =============================
	.blocs_icones{
		margin-top: 40px;
		margin-bottom: 40px;

		.bloc:nth-child(1){

		}
		.bloc:nth-child(2) img {
			width: 70px;
		}
		.bloc:nth-child(3) img {
			width: 60px;
		}
		.bloc:nth-child(4) img {
			width: 65px;
		}

		@include media("<750px"){
			margin-bottom: 20px;
		}
	}



	//- Argumentaire
	//- =============================

	.argumentaire{

		margin-top: 50px;

		h2{
			// margin-bottom: 30px;

			@include media("<750px"){
				margin-bottom: 20px;
			}
		}
		
		.texte{
			width: 48%;
			display: inline-block;

			@include media("<750px"){
				display: block;
				width: auto;
				text-align: center;
			}
		}

		.photo{
			display: inline-block;
			width: 45%;
			margin-left: 5%;

			@include media("<750px"){
				display: block;
				width: 90%;
				margin-top: 30px;
			}
		}

	}


	//- Il parlent de bous
	//- =============================
	.ils_parlent_de_nous{
		margin-top: 50px;
		background: $smoke;
		padding: $main_padding;

		h2{
			margin-top: 10px;
			margin-bottom: 20px;
		}

		.video{
			display: inline-block;
			vertical-align: top;

			iframe{
				width: 560px;
				height: 345px;
				@include media("<1135px"){
					width: 460px;
					height: 280px;
				}
				@include media("<1030px"){
					width: 100%;
				}
				
			}
		}

		.article_presse{
			margin-left: 5%;
			display: inline-block;

			@include media("<860px"){
				margin-top: 20px;
			}

			.titre{
				font-weight: bold;
				font-size: 18px;
			}

			a.lien{
				@include button($color: #7db22b);
				display: block;
				max-width: 240px;
				margin-top: 8px;
				margin-bottom: 8px;
			}

			img{

			}
		}
	}

} //- End page
